div.ballade {
    margin: 1% 2%;
}

h4.ballHeader {
    font: 700 17px/23px Lato, Arial, sans-serif;
    color: #333;
    text-align: center;
    margin-bottom: 10px; 
}

li.slide p {
    font: 400 15px/17px Lato, Arial, sans-serif;
}

div.ballade .carousel-root {
    width: 75%;
    margin: 0 auto;
}

@media screen and (max-width: 767px) and (min-width: 465px) {
    div.ballade  { margin: 1% 0%; }
    div.ballade .carousel-root { width: 100%; }
}

@media screen and (max-width: 464px) {
    div.ballade  { margin: .5% 0%; }
    div.ballade .carousel-root { width: 100%; }
}