a:active,
a:visited,
a:link
{
  color: #222;
  text-decoration: underline;
  cursor: pointer;
}

.loading {
  font: 700 23px/28px Farsan, Lato,Arial,sans-serif;
  margin: 1.5%;
}