@font-face {
    font-family: 'Indie Flower';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/indie-flower-v9-latin-regular.5ffb4faf.eot); /* IE9 Compat Modes */
    src: local('Indie Flower'), local('IndieFlower'),
         url(/static/media/indie-flower-v9-latin-regular.5ffb4faf.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/indie-flower-v9-latin-regular.7dcc3101.woff2) format('woff2'), 
         url(/static/media/indie-flower-v9-latin-regular.a6e128d6.woff) format('woff'), 
         url(/static/media/indie-flower-v9-latin-regular.2eed411f.ttf) format('truetype'), 
         url(/static/media/indie-flower-v9-latin-regular.ecfda83e.svg#IndieFlower) format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/lora-v12-latin-regular.1fd18c6e.eot); /* IE9 Compat Modes */
    src: local('Lora Regular'), local('Lora-Regular'),
         url(/static/media/lora-v12-latin-regular.1fd18c6e.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/lora-v12-latin-regular.f2160dd7.woff2) format('woff2'), 
         url(/static/media/lora-v12-latin-regular.907cc8d1.woff) format('woff'), 
         url(/static/media/lora-v12-latin-regular.fbb251a4.ttf) format('truetype'), 
         url(/static/media/lora-v12-latin-regular.365872e4.svg#Lora) format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Farsan';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/farsan-v3-latin-regular.0e37624e.eot); /* IE9 Compat Modes */
    src: local('Farsan Regular'), local('Farsan-Regular'),
         url(/static/media/farsan-v3-latin-regular.0e37624e.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/farsan-v3-latin-regular.99017344.woff2) format('woff2'), 
         url(/static/media/farsan-v3-latin-regular.287fb1e6.woff) format('woff'), 
         url(/static/media/farsan-v3-latin-regular.26598897.ttf) format('truetype'), 
         url(/static/media/farsan-v3-latin-regular.3f465ffe.svg#Farsan) format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/lato-v14-latin-regular.6a6d7150.eot); /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'),
         url(/static/media/lato-v14-latin-regular.6a6d7150.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/lato-v14-latin-regular.f1a4a058.woff2) format('woff2'), 
         url(/static/media/lato-v14-latin-regular.62fb51e9.woff) format('woff'), 
         url(/static/media/lato-v14-latin-regular.da4b79be.ttf) format('truetype'), 
         url(/static/media/lato-v14-latin-regular.9087e4a6.svg#Lato) format('svg'); /* Legacy iOS */
  }
  @font-face
  {
      font-family:'Shadowed Germanica';
      src:
          url(/static/media/ShadowedGermanica.71067912.eot);
      src: local('Shadowed Germanica'), local('Shadowed-Germanica'),
          url(/static/media/ShadowedGermanica.71067912.eot?#iefix) format('embedded-opentype'), 
          url(/static/media/ShadowedGermanica.3fad0a69.woff) format('woff'),
          url(/static/media/ShadowedGermanica.4d4279d1.ttf) format('truetype');
  }
* {margin: 0; padding:0 ;}
a:active,
a:visited,
a:link
{
  color: #222;
  text-decoration: underline;
  cursor: pointer;
}

.loading {
  font: 700 23px/28px Farsan, Lato,Arial,sans-serif;
  margin: 1.5%;
}
div.ballade {
    margin: 1% 2%;
}

h4.ballHeader {
    font: 700 17px/23px Lato, Arial, sans-serif;
    color: #333;
    text-align: center;
    margin-bottom: 10px; 
}

li.slide p {
    font: 400 15px/17px Lato, Arial, sans-serif;
}

div.ballade .carousel-root {
    width: 75%;
    margin: 0 auto;
}

@media screen and (max-width: 767px) and (min-width: 465px) {
    div.ballade  { margin: 1% 0%; }
    div.ballade .carousel-root { width: 100%; }
}

@media screen and (max-width: 464px) {
    div.ballade  { margin: .5% 0%; }
    div.ballade .carousel-root { width: 100%; }
}
.fb_iframe_widget {
    margin: 10px 0px;
}
