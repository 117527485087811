@font-face {
    font-family: 'Indie Flower';
    font-style: normal;
    font-weight: 400;
    src: url('./indie-flower-v9-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Indie Flower'), local('IndieFlower'),
         url('./indie-flower-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./indie-flower-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./indie-flower-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./indie-flower-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./indie-flower-v9-latin-regular.svg#IndieFlower') format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    src: url('./lora-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Lora Regular'), local('Lora-Regular'),
         url('./lora-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./lora-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./lora-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./lora-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./lora-v12-latin-regular.svg#Lora') format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Farsan';
    font-style: normal;
    font-weight: 400;
    src: url('./farsan-v3-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Farsan Regular'), local('Farsan-Regular'),
         url('./farsan-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./farsan-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./farsan-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./farsan-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./farsan-v3-latin-regular.svg#Farsan') format('svg'); /* Legacy iOS */
  }
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('./lato-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'),
         url('./lato-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./lato-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./lato-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./lato-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./lato-v14-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
  }
  @font-face
  {
      font-family:'Shadowed Germanica';
      src:
          url('./ShadowedGermanica.eot');
      src: local('Shadowed Germanica'), local('Shadowed-Germanica'),
          url('./ShadowedGermanica.eot?#iefix') format('embedded-opentype'), 
          url('./ShadowedGermanica.woff') format('woff'),
          url('./ShadowedGermanica.ttf') format('truetype');
  }